import { render, staticRenderFns } from "./catalog-item-small-desktop.vue?vue&type=template&id=c11bd932"
import script from "./catalog-item-small-desktop.vue?vue&type=script&lang=js"
export * from "./catalog-item-small-desktop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CatalogItemHoverSlider: require('/home/d/didisher2/autodrive.spb.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-hover-slider.vue').default,CatalogItemTitle: require('/home/d/didisher2/autodrive.spb.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-title.vue').default,CatalogItemPrice: require('/home/d/didisher2/autodrive.spb.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-price.vue').default,CatalogItemTechList: require('/home/d/didisher2/autodrive.spb.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-tech-list.vue').default,CatalogItemButtons: require('/home/d/didisher2/autodrive.spb.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-buttons.vue').default})
