
import filters from "~/mixins/filters";
// import { mapActions, mapMutations } from "vuex";

export default {
  mixins: [filters],
  computed: {
    currentCategory() {
      return this.offer.category_enum;
    },
    currentMark() {
      return this.offer.mark.slug;
    },
    currentFolder() {
      return this.offer.folder.slug;
    },
    currentId() {
      return this.offer.external_id;
    },
  },
  props: {
    isForm: {
      type: Boolean,
      default: false,
    },
    url: String,
    small: {
      type: Boolean,
      default: false,
    },
    offer: {
      type: Object,
      default: () => {},
    },
  },
};
